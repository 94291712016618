import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConnectionStatus } from "../api/graphql";
import { useCurrentCorrespondent } from "../SessionBoundary";
import RejectionAlert from "./RejectionAlert";

export default function useConnectionRequestRejections() {
  const user = useCurrentCorrespondent();
  if (user.__typename !== "Visitor") return [];
  return user.connections.filter((c) => c.status === ConnectionStatus.Rejected);
}

export function RejectedConnectionAlert({
  id,
  name,
}: {
  id: string;
  name: string;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <RejectionAlert
      title={t("You have a rejected contact request with {{name}}", {
        name,
      })}
      onClick={() => navigate(`/contacts/${id}`)}
    />
  );
}
