import {
  SelectInput,
  SubmitButton,
  TextInput,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  OrganizationMembershipStatus,
  OrganizationType,
  UsState,
} from "../api/graphql";
import { ContentSkeleton } from "../lib/closet";
import { AvailableCountry, getCountryLabel } from "../lib/DocumentType";
import useApolloErrorHandler from "../lib/handleApolloError";
import ImageInput from "../lib/ImageInput";
import organizationTypeLabel from "../lib/organizationTypeLabel";
import Screen from "../lib/Screen";
import ScreenSection from "../lib/ScreenSection";
import useFileUpload from "../lib/useFileUpload";
import USState from "../lib/USState.enum";
import {
  AvailableValidator,
  getValidator,
  isEmail,
  MAX_PHONE_NUMBER_LENGTH,
} from "../lib/validations";
import { useCurrentVisitor } from "../SessionBoundary";
import { CreateOrganizationDocument } from "../Toolbox/CreateOrganization.generated";
import { GetInviteOrganizationByUserDocument } from "./GetInviteOrganizationByUser.generated";

type FormData = {
  name: string;
  type: OrganizationType;
  description: string;
  websiteLink?: string;
  addressLineOne: string;
  addressLineTwo?: string;
  city: string;
  state: UsState;
  province: string;
  country: string;
  postal: string;
  phone: string;
  email: string;
  logoImage: File;
};

const ensureURLProtocol = (url?: string) =>
  url && !url.match(/^http(s)?:\/\//) ? `https://${url}` : url;

export default function NewOrganizationScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleApolloError = useApolloErrorHandler();
  const currentVisitor = useCurrentVisitor();
  const snackbarContext = useSnackbarContext();

  useEffect(() => {
    // If the user is already a member of an organization, redirect them to the team page
    if (currentVisitor.organizationMemberships.length > 0) {
      const membership = currentVisitor.organizationMemberships.find(
        (r) => r.status === OrganizationMembershipStatus.Active
      );
      if (membership) {
        navigate(`/organization/${membership.organization.id}/team`);
      }
    }
  }, [currentVisitor, navigate]);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      country: AvailableCountry.US,
    },
  });

  const [createOrganization] = useMutation(CreateOrganizationDocument, {
    onError: handleApolloError,
  });

  const { data: orgInvitationData, loading } = useQuery(
    GetInviteOrganizationByUserDocument,
    {
      onError: handleApolloError,
    }
  );

  useEffect(() => {
    if (orgInvitationData && orgInvitationData.organizationInvitationByUser) {
      navigate("/");
      snackbarContext.alert(
        "warning",
        t(
          "You already have a pending invitation for an organization. Please contact the organization administrator for an updated invitation email."
        )
      );
    }
  }, [orgInvitationData, navigate, snackbarContext, t]);

  const upload = useFileUpload();

  const countryValue = watch("country");

  const inferProvince = (formData: FormData) =>
    formData.country === AvailableCountry.US
      ? { province: USState[formData.state] }
      : { province: formData.province, state: UsState.Ak };

  const snackbar = useSnackbarContext();

  const onSubmit = async (data: FormData) => {
    const { logoImage, ...remainingData } = data;
    const logoImageKey = await upload(logoImage);
    await createOrganization({
      variables: {
        input: {
          ...remainingData,
          logoImageKey,
          ...inferProvince(data),
          websiteLink: ensureURLProtocol(data.websiteLink),
        },
      },
      onCompleted: () => {
        snackbar.alert(
          "success",
          t(
            "Organization created successfully. You may now request professional contacts."
          )
        );
        navigate(`/`);
      },
    });
  };

  return loading ? (
    <ContentSkeleton />
  ) : (
    <Screen title={t("Create organization")}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div>
            <Grid
              sx={{
                height: 1,
                overflowY: "auto",
              }}
            >
              <ScreenSection title={t("Details")}>
                <Stack spacing={2} pb={2}>
                  <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                    <Box
                      sx={{
                        width: "128px",
                        height: "128px",
                      }}
                    >
                      <ImageInput
                        control={control}
                        name="logoImage"
                        rules={{ validate: (v) => !!v }}
                        primaryLabel={t("Click or drag to upload logo")}
                        hideFormatLabel
                        round
                      />
                    </Box>
                    <Stack spacing={2} flex="1">
                      <TextInput
                        control={control}
                        name="name"
                        label={t("Name")}
                        rules={{
                          required: {
                            value: true,
                            message: t("Field is required"),
                          },
                        }}
                      />
                      <SelectInput
                        control={control}
                        name="type"
                        label={t("Type")}
                        rules={{
                          required: {
                            value: true,
                            message: t("Field is required"),
                          },
                        }}
                        items={Object.values(OrganizationType).map((v) => ({
                          value: v,
                          name: organizationTypeLabel(v),
                        }))}
                      />
                    </Stack>
                  </Stack>
                  <TextInput
                    control={control}
                    name="description"
                    label={t("Description")}
                    rules={{
                      required: {
                        value: true,
                        message: t("Field is required"),
                      },
                    }}
                    multiline
                    minRows={5}
                    maxRows={10}
                  />
                </Stack>
              </ScreenSection>
              <ScreenSection title={t("Address")}>
                <Stack spacing={2} pb={2}>
                  <TextInput
                    control={control}
                    name="addressLineOne"
                    label={t("Line One")}
                    rules={{
                      required: {
                        value: true,
                        message: t("Field is required"),
                      },
                    }}
                  />
                  <TextInput
                    control={control}
                    name="addressLineTwo"
                    label={t("Line Two")}
                    rules={{ required: false }}
                  />
                  <TextInput
                    control={control}
                    name="city"
                    label={t("City")}
                    rules={{
                      required: {
                        value: true,
                        message: t("Field is required"),
                      },
                    }}
                  />
                  <SelectInput
                    items={Object.keys(AvailableCountry).map((v) => ({
                      value: v,
                      name: getCountryLabel(v as AvailableCountry),
                    }))}
                    name="country"
                    label={t("Country")}
                    control={control}
                    rules={{ validate: (v) => !!v }}
                  />
                  {countryValue === AvailableCountry.US ? (
                    <SelectInput
                      control={control}
                      name="state"
                      label={t("State")}
                      rules={{ required: true }}
                      items={Object.values(UsState).map((v) => ({
                        value: v,
                        name: USState[v],
                      }))}
                    />
                  ) : (
                    <TextInput
                      control={control}
                      name="province"
                      label={t("Province")}
                      rules={{
                        required: {
                          value: true,
                          message: t("Field is required"),
                        },
                      }}
                    />
                  )}
                  <TextInput
                    control={control}
                    name="postal"
                    label={
                      countryValue === AvailableCountry.US
                        ? t("ZIP code")
                        : t("Postal code")
                    }
                    rules={{
                      required: {
                        value: true,
                        message: t("Field is required"),
                      },
                    }}
                  />
                </Stack>
              </ScreenSection>
              <ScreenSection title={t("Contact")}>
                <Stack spacing={2} pb={2}>
                  <TextInput
                    control={control}
                    name="phone"
                    label={t("Phone number")}
                    inputProps={{ maxLength: MAX_PHONE_NUMBER_LENGTH }}
                    rules={getValidator(AvailableValidator.PHONE_NUMBER)}
                  />
                  <TextInput
                    control={control}
                    name="email"
                    label={t("Email Address")}
                    rules={{
                      validate: (v) =>
                        isEmail(v) || t("Please enter a valid email address"),
                    }}
                  />
                  <TextInput
                    control={control}
                    helperText={t("If included, must be a valid URL")}
                    name="websiteLink"
                    label={t("Website URL")}
                    rules={{ required: false }}
                  />
                </Stack>
              </ScreenSection>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <SubmitButton submitting={isSubmitting} sx={{ width: "auto" }}>
                  {t("Submit")}
                </SubmitButton>
              </Box>
            </Grid>
          </div>
        </div>
      </form>
    </Screen>
  );
}
