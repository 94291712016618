import { Avatar, InlineBadge, Media } from "@ameelio/ui";
import { CameraAltOutlined, InfoOutlined } from "@mui/icons-material";
import { Box, capitalize, Typography } from "@mui/material";
import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { ConnectionTimelineEventWithObjectFragmentFragment } from "../api/ConnectionTimelineEventWithObjectFragment.generated";
import {
  Connection,
  ConnectionRestriction,
  FacilityService,
  InstantReply,
  MeetingStatus,
  MeetingType,
  MessageAvailability,
  MessageConnection,
  MessageType,
} from "../api/graphql";
import Link from "../lib/Link";
import meetingTypeLabel from "../lib/meetingTypeLabel";
import TruncatedTypography from "../lib/TruncatedTypography";
import { useCurrentCorrespondent } from "../SessionBoundary";
import { shade } from "../theme";
import getInstantReplyContent from "./getInstantReplyContent";

type TimelineEvent = ConnectionTimelineEventWithObjectFragmentFragment;

type Props = {
  selected: boolean;
  contact: { firstName: string; lastName: string; fullName: string };
  connection: Pick<Connection, "id"> & {
    messages: Pick<MessageConnection, "unreadMessageCount">;
    timelineEvents: { edges: { node: TimelineEvent }[] };
    activeRestrictions: Pick<ConnectionRestriction, "service">[];
  };
};

function Restricted() {
  const { t } = useTranslation();
  return (
    <Media gap={0.5} image={<InfoOutlined fontSize="inherit" />}>
      {t("Restricted")}
    </Media>
  );
}

function PhotoDetail() {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CameraAltOutlined fontSize="inherit" color="inherit" />
      <Box ml={0.5}>
        <Typography variant="body2" color="text.primary">
          {t("Photo")}
        </Typography>
      </Box>
    </Box>
  );
}

function lastMessageDetail(
  t: TFunction<"translation", undefined>,
  object: Extract<TimelineEvent["object"], { __typename: "Message" }>,
  senderIsMe: boolean
) {
  const messageContent = (() => {
    const content = object.isInstantReply
      ? getInstantReplyContent(t, object.content as InstantReply)
      : object.content;
    return senderIsMe ? `${t("You:")} ${content}` : content;
  })();

  return object.availability === MessageAvailability.Rejected ? (
    object.type === MessageType.Photo ? (
      t("Photo rejected")
    ) : (
      t("Message rejected")
    )
  ) : object.availability === MessageAvailability.Pending ? (
    object.type === MessageType.Photo ? (
      t("Photo under review")
    ) : (
      t("Message under review")
    )
  ) : object.type === MessageType.Photo ? (
    <PhotoDetail />
  ) : (
    messageContent
  );
}

function lastMeetingDetail(
  t: TFunction<"translation", undefined>,
  object: Extract<TimelineEvent["object"], { __typename: "Meeting" }>
) {
  return object.meetingType === MeetingType.VoiceCall &&
    object.status === MeetingStatus.NoShow
    ? t("Missed voice call")
    : capitalize(meetingTypeLabel(object.meetingType));
}

export default function ConnectionsListItem({
  selected,
  contact,
  connection,
}: Props) {
  const { t } = useTranslation();
  const user = useCurrentCorrespondent();

  const restricted = connection.activeRestrictions.some(
    (r) => r.service === FacilityService.Emessaging
  );

  const lastTimelineEventObject =
    connection.timelineEvents.edges[0]?.node.object;
  const detail = restricted ? (
    <Restricted />
  ) : !lastTimelineEventObject ? (
    t("No history")
  ) : lastTimelineEventObject.__typename === "Connection" ? (
    t("You and {{firstName}} connected.", {
      firstName: lastTimelineEventObject.contact.firstName,
    })
  ) : lastTimelineEventObject.__typename === "Message" ? (
    lastMessageDetail(
      t,
      lastTimelineEventObject,
      lastTimelineEventObject.sender.__typename === user.__typename
    )
  ) : lastTimelineEventObject.__typename === "Meeting" ? (
    lastMeetingDetail(t, lastTimelineEventObject)
  ) : (
    // connection restriction events aren't published in the timeline,
    // So this text shouldn't show up:
    t("Conn. restriction event")
  );

  const unread = connection.messages.unreadMessageCount > 0;

  return (
    <Link
      sx={{
        textDecoration: "none",
        display: "flex",
        flexDirection: "row",
        paddingX: 2,
        paddingY: 1.5,
        backgroundColor: selected
          ? ({ palette }) => shade(palette.primary.main, 8)
          : undefined,
        "&:hover": {
          backgroundColor: "action.hover",
        },
        overflow: "hidden",
      }}
      to={`/messages/${connection.id}`}
    >
      <Avatar
        contact={contact}
        sx={{ opacity: restricted ? 0.5 : undefined }}
      />
      <Box flexGrow={1} minWidth={0} ml={2}>
        <TruncatedTypography
          variant="body1"
          color={restricted ? "text.secondary" : "text.primary"}
          sx={{ fontWeight: unread ? "bold" : undefined }}
        >
          {contact.fullName}
        </TruncatedTypography>
        <TruncatedTypography
          variant="body2"
          component="div"
          color={restricted ? "text.secondary" : "text.primary"}
          sx={{ fontWeight: unread ? "bold" : undefined }}
        >
          {detail}
        </TruncatedTypography>
      </Box>
      <InlineBadge
        color="primary"
        badgeContent={connection.messages.unreadMessageCount}
      />
    </Link>
  );
}
