import { Box, Container, Skeleton, SkeletonProps, Stack } from "@mui/material";
import React from "react";
import { ScreenTitle } from "./typography";

export function BaseSkeleton({ sx, ...rest }: SkeletonProps) {
  return (
    <Skeleton
      {...rest}
      sx={{
        ...(sx || {}),
        // NOTE: Light/Action/Hover (4p)
        backgroundColor: "rgb(0 0 0 / 0.04)",
        borderRadius: 4,
      }}
    />
  );
}

export function TableSkeleton() {
  return <BaseSkeleton variant="rectangular" height={400} />;
}

export function TitleSkeleton({ width }: { width?: number }) {
  return (
    <ScreenTitle>
      <BaseSkeleton variant="text" width={width || 200} />
      &nbsp;
    </ScreenTitle>
  );
}

export function CardSkeleton({ height }: { height?: number }) {
  return <BaseSkeleton variant="rectangular" height={height || 120} />;
}

export function ContentSkeleton() {
  return (
    <>
      <TitleSkeleton />
      <Box width="100%" maxWidth={600} marginX="auto" mt={4}>
        <TableSkeleton />
      </Box>
    </>
  );
}

export function ListSkeleton() {
  return (
    <>
      <TitleSkeleton />
      <Container maxWidth="sm" sx={{ mt: 4, marginX: "auto" }}>
        <Stack spacing={4}>
          <TitleSkeleton />
          <CardSkeleton />

          <TitleSkeleton />
          <CardSkeleton />

          <TitleSkeleton />
          <CardSkeleton />
        </Stack>
      </Container>
    </>
  );
}

export function IconSkeleton({ size }: { size?: number }) {
  return (
    <Skeleton
      variant="circular"
      width={size || 40}
      height={size || 40}
      aria-label="Skeleton"
      sx={{
        backgroundColor: "rgb(0 0 0 / 0.04)",
        borderRadius: "50%",
      }}
    />
  );
}
