import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enMonolith from "./monolith/en.json";
import esMonolith from "./monolith/es.json";
import itMonolith from "./monolith/it.json";
import esNotifications from "./notifications/es.json";
import itNotifications from "./notifications/it.json";

const I18nNamespaceList = ["monolith", "notifications"];

const resources = {
  es: {
    monolith: esMonolith,
    notifications: esNotifications,
  },
  en: {
    monolith: enMonolith,
  },
  it: {
    monolith: itMonolith,
    notifications: itNotifications,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  ns: I18nNamespaceList,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resources: resources as any,
  // allow keys to be phrases having `:` and `.`
  nsSeparator: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
