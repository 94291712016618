import { IconButton, Tooltip } from "@ameelio/ui";
import {
  ClosedCaptionDisabledOutlined,
  ClosedCaptionOutlined,
} from "@mui/icons-material";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import ScreenShareOutlined from "@mui/icons-material/ScreenShareOutlined";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import { alpha, Badge, Box, Button, SxProps, Theme } from "@mui/material";
import { common, grey, red } from "@mui/material/colors";
import React from "react";
import { useTranslation } from "react-i18next";
import { lightPalette, ltrTheme } from "../../theme";
import MediaControls, { Props as MediaControlProps } from "../MediaControls";

const offStyle: SxProps<Theme> = {
  "&:hover": {
    backgroundColor: lightPalette.primary.dark,
    color: common.white,
  },
};

type Props = MediaControlProps & {
  onLeave: () => void;
  onToggleChat?: () => void;
  backgroundSelectionDisabled?: boolean;
  onToggleBackgroundSelection?: () => void;
  leaveCallDisabled?: boolean;
  disabledReason?: string;
  alerts: boolean;
  isScreensharing?: boolean;
  onToggleAlerts?: () => void;
  screenshareDisabled?: boolean;
  onToggleScreenshare?: () => void;
  messageCount?: number;
  leaveCallLabel: string;
  styleOverrides?: SxProps<Theme>;
  onToggleCaptions?: () => void;
  captions?: boolean;
  captionsDisabled?: boolean;
};

export default function CallControls({
  onToggleChat,
  onToggleAlerts,
  leaveCallDisabled,
  disabledReason,
  backgroundSelectionDisabled,
  onToggleBackgroundSelection,
  screenshareDisabled,
  onToggleScreenshare,
  alerts,
  isScreensharing,
  messageCount,
  onLeave,
  leaveCallLabel,
  styleOverrides,
  captions,
  onToggleCaptions,
  captionsDisabled,
  ...mediaControlProps
}: Props) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        background: (th) => alpha(th.palette.background.default, 0.8),
        borderRadius: "10000px",
        px: 2,
        py: 1.5,
        backdropFilter: "blur(5px)",
        height: ltrTheme.spacing(8),
        "> span": {
          my: 0,
          mx: { xs: 1, md: 2 },
        },
        "> span:last-child": {
          mt: 0,
          mr: 0,
          mb: 0,
          ml: 2,
        },
        whiteSpace: "nowrap",
        ...(styleOverrides || {}),
      }}
    >
      <MediaControls {...mediaControlProps} />
      {onToggleCaptions && (
        <IconButton
          tooltip
          sx={{
            backgroundColor: () =>
              !captions ? lightPalette.primary.main : "auto",
            color: !captions ? common.white : "auto",
            ...(!captions ? offStyle : {}),
          }}
          ariaLabel={
            captionsDisabled
              ? t("Captions are disabled")
              : captions
                ? t("Turn off captions")
                : t("Turn on captions")
          }
          disabled={captionsDisabled}
          onClick={onToggleCaptions}
        >
          {captions ? (
            <ClosedCaptionOutlined />
          ) : (
            <ClosedCaptionDisabledOutlined />
          )}
        </IconButton>
      )}
      {onToggleScreenshare && (
        <IconButton
          tooltip
          disabled={screenshareDisabled}
          onClick={onToggleScreenshare}
          ariaLabel={
            screenshareDisabled
              ? t("Screensharing is disabled")
              : isScreensharing
                ? t("Stop sharing")
                : t("Share screen")
          }
          sx={{
            backgroundColor: () => (isScreensharing ? grey[700] : "auto"),
            color: isScreensharing ? common.white : "auto",
            ...(isScreensharing
              ? {
                  "&:hover": {
                    backgroundColor: grey[700],
                  },
                }
              : {}),
          }}
        >
          {isScreensharing ? <ScreenShareOutlined /> : <ScreenShareOutlined />}
        </IconButton>
      )}
      {onToggleChat && (
        <IconButton tooltip ariaLabel={t("Open chat")} onClick={onToggleChat}>
          <Badge
            variant={messageCount ? "standard" : "dot"}
            invisible={!messageCount}
            color="primary"
            badgeContent={messageCount}
          >
            <ChatOutlinedIcon />
          </Badge>
        </IconButton>
      )}
      {onToggleAlerts && (
        <IconButton
          tooltip
          ariaLabel={alerts ? t("Turn off alerts") : t("Turn on alerts")}
          onClick={() => {
            onToggleAlerts();
          }}
          sx={{
            backgroundColor: () =>
              !alerts ? lightPalette.primary.main : "auto",
            color: !alerts ? common.white : "auto",
            ...(!alerts ? offStyle : {}),
          }}
        >
          {alerts ? <NotificationsNoneIcon /> : <NotificationsOffIcon />}
        </IconButton>
      )}
      {onToggleBackgroundSelection && (
        <IconButton
          tooltip
          disabled={backgroundSelectionDisabled}
          ariaLabel={
            backgroundSelectionDisabled
              ? t("Custom backgrounds are disabled")
              : t("Set custom background")
          }
          onClick={() => onToggleBackgroundSelection()}
        >
          <WallpaperIcon />
        </IconButton>
      )}
      <Tooltip
        title={disabledReason || t("Call cannot be left yet")}
        disabled={!leaveCallDisabled}
      >
        {/* Disabled buttons don't fire events, wrapping in span below for the tooltip display */}
        <span>
          <Button
            onClick={onLeave}
            disabled={leaveCallDisabled}
            sx={{
              whiteSpace: "nowrap",
              backgroundColor: red[700],
              borderRadius: "10000px",
              color: common.white,
              "&:hover": {
                backgroundColor: lightPalette.error.main,
              },
            }}
          >
            {leaveCallLabel}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
}
