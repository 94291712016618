import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Link as ExternalLink } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ContentSkeleton } from "../lib/closet";
import Link from "../lib/Link";
import Screen from "../lib/Screen";
import { GetOrganizationPartnersDocument } from "./GetOrganizationPartners.generated";

export default function OrganizationPartnershipsScreen() {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("missing id param");

  const { t } = useTranslation();

  const { data, error } = useQuery(GetOrganizationPartnersDocument, {
    variables: { organizationId: id },
    fetchPolicy: "cache-and-network",
  });

  if (error) throw error;
  if (!data) return <ContentSkeleton />;

  const { organization } = data;

  return (
    <Screen
      title={t("{{organizationName}}: Partners", {
        organizationName: organization.name,
      })}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Facility Name</TableCell>
              <TableCell>System</TableCell>
              <TableCell>City</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Policy Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organization.connectedFacilities.map((cf) => (
              <TableRow key={cf.id}>
                <TableCell>{cf.name}</TableCell>
                <TableCell>
                  <Link to={`/toolbox/systems/${cf.system.id}`}>
                    {cf.system.code}
                  </Link>
                </TableCell>
                <TableCell>{cf.city}</TableCell>
                <TableCell>{cf.state}</TableCell>
                <TableCell>
                  <ExternalLink href={cf.policyLink}>
                    {cf.policyLink}
                  </ExternalLink>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Screen>
  );
}
