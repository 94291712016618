import { useSnackbarContext } from "@ameelio/ui";
import { useTranslation } from "react-i18next";
import getInstantReplyContent, {
  InstantReplyType,
} from "../Messages/getInstantReplyContent";
import { useServerEvent } from "./serverEventsProvider";

function GlobalServerEventsListener() {
  const snackbarContext = useSnackbarContext();
  const { t, i18n } = useTranslation();

  useServerEvent("newInstantMessageReply", ({ sender, content }) => {
    snackbarContext.alert(
      "warning",
      t(`New instant message reply from {{sender}}: {{content}}`, {
        sender,
        content: getInstantReplyContent(i18n.t, InstantReplyType[content]),
      })
    );
  });

  return null;
}

export default GlobalServerEventsListener;
