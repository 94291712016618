import { IconButton } from "@ameelio/ui";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Container,
  Paper,
  useMediaQuery as measureScreenWidth,
  useTheme,
} from "@mui/material";
import { common } from "@mui/material/colors";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import ameelio from "./ameelio.svg";
import featureEnabled from "./lib/featureEnabled";
import { belowLargeTablet } from "./lib/responsiveHelpers";
import {
  CorrespondentMenu,
  MenuProps,
  MenuTab,
  ToolboxMenu,
} from "./Navigation/Menus";
import Notifications from "./Notifications/Notifications";
import { useCurrentUser } from "./SessionBoundary";
import VoiceCallListener from "./VoiceCall/VoiceCallListener";

const DRAWER_WIDTH = 240;

type LayoutProps = {
  currentTab: MenuTab;
  children: React.ReactNode;
};

function LogoContainer() {
  return (
    <Box
      mx={3}
      mt={2}
      mb={5}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <img src={ameelio} alt="Ameelio" style={{ width: 100 }} />
    </Box>
  );
}

function MenusContainer({ currentTab, onClick }: MenuProps) {
  const user = useCurrentUser();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      {(user.__typename === "Visitor" || user.__typename === "Inmate") && (
        <CorrespondentMenu currentTab={currentTab} onClick={onClick} />
      )}
      {user.__typename === "Staff" && (
        <ToolboxMenu currentTab={currentTab} onClick={onClick} />
      )}
    </Box>
  );
}

function MobileSmallTabletLayout({ currentTab, children }: LayoutProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const handleClick = () => {
    setMenuOpen(false);
  };
  const menuChildren = (
    <>
      <MenusContainer currentTab={currentTab} onClick={handleClick} />
      <LogoContainer />
    </>
  );
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: 1, width: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: 1,
          minHeight: theme.spacing(8),
          px: 2,
          backgroundColor: common.white,
          position: "fixed",
          zIndex: 1100,
        }}
      >
        <IconButton
          sx={{
            color: "text.primary",
          }}
          ariaLabel={t("Menu")}
          size="large"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <MenuIcon />
        </IconButton>
        <img src={ameelio} alt="Ameelio" style={{ width: 100 }} />
        <Notifications />
      </Box>
      {menuOpen && (
        <>
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              height: 1,
              width: 1,
              backgroundColor: theme.palette.text.secondary,
              zIndex: 1101,
            }}
            onClick={() => setMenuOpen(false)}
          />
          {featureEnabled("RECEIVE_VOICE_CALLS") && <VoiceCallListener />}
          <Paper
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              height: 1,
              borderRadius: `0 ${theme.spacing(2)} ${theme.spacing(2)} 0`,
              display: "flex",
              minWidth: DRAWER_WIDTH,
              flexDirection: "column",
              maxHeight: "100%",
              overflow: "auto",
              zIndex: "modal", // 1300
            }}
          >
            {menuChildren}
          </Paper>
        </>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: 1,
          padding: 2,
          paddingTop: 8,
          marginLeft: 0,
          maxWidth: "100%",
        }}
      >
        {children || <Outlet />}
      </Box>
    </Box>
  );
}

function LargeTabletLaptopUpLayout({ currentTab, children }: LayoutProps) {
  const menuChildren = (
    <>
      <MenusContainer currentTab={currentTab} />
      <LogoContainer />
    </>
  );
  return (
    <Container maxWidth="lg" sx={{ display: "flex", padding: 3, height: 1 }}>
      {featureEnabled("RECEIVE_VOICE_CALLS") && <VoiceCallListener />}
      <Paper
        sx={{
          borderRadius: 4,
          minWidth: DRAWER_WIDTH,
          maxWidth: DRAWER_WIDTH,
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          overflow: "auto",
        }}
      >
        {menuChildren}
      </Paper>
      <Box
        component="main"
        ml={3}
        sx={{
          flexGrow: 1,
          maxWidth: "100%",
        }}
      >
        {children || <Outlet />}
      </Box>
    </Container>
  );
}

export default function MenuLayout({
  currentTab,
  children,
}: {
  currentTab: MenuTab;
  children?: React.ReactNode;
}) {
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);

  if (isMobileOrSmallTablet) {
    return (
      <MobileSmallTabletLayout currentTab={currentTab}>
        {children}
      </MobileSmallTabletLayout>
    );
  }

  return (
    <LargeTabletLaptopUpLayout currentTab={currentTab}>
      {children}
    </LargeTabletLaptopUpLayout>
  );
}
