import { useMemo } from "react";
import { Interval, User } from "../api/graphql"; // Adjust the import paths as necessary

type UserProps = Pick<User, "__typename">;

type GroupProps = {
  currentVoiceCallSlot: Interval | null;
  nextVoiceCallSlot: Interval | null;
  hasVoiceCallSlots: boolean;
};

export default function useVoiceCallPermission(
  user: UserProps,
  group?: GroupProps
) {
  // An inmate can make a voice call if the facility
  // hasn't set the voiceCallSlots, or if there is a current voiceCallSlot.
  const currentVoiceCallSlot = group?.currentVoiceCallSlot || null;
  const nextVoiceCallSlot = group?.nextVoiceCallSlot || null;

  const userMayMakeVoiceCall = useMemo(
    () =>
      user.__typename === "Inmate" &&
      (!group?.hasVoiceCallSlots || !!currentVoiceCallSlot),
    [user, group, currentVoiceCallSlot]
  );

  return { currentVoiceCallSlot, nextVoiceCallSlot, userMayMakeVoiceCall };
}
