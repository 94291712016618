import { MeetingType } from "../api/graphql";
import i18n from "../i18n/i18nConfig";

export default function meetingTypeLabel(
  mt: MeetingType,
  opts?: { plural?: boolean }
) {
  let Labels: Record<MeetingType, string>;
  // Moved inside the function to allow for correct translation
  if (opts?.plural) {
    Labels = {
      [MeetingType.InPersonVisit]: i18n.t("visits"),
      [MeetingType.VideoCall]: i18n.t("video calls"),
      [MeetingType.VoiceCall]: i18n.t("voice calls"),
      [MeetingType.Webinar]: i18n.t("webinars"),
    };
  } else {
    Labels = {
      [MeetingType.InPersonVisit]: i18n.t("visit"),
      [MeetingType.VideoCall]: i18n.t("video call"),
      [MeetingType.VoiceCall]: i18n.t("voice call"),
      [MeetingType.Webinar]: i18n.t("webinar"),
    };
  }
  return Labels[mt];
}
