import { Box, Container, ContainerProps } from "@mui/material";
import React from "react";
import ScreenHeader from "./ScreenHeader";

type ScreenContentsProps = Omit<ContainerProps, "maxWidth">;

export function ScreenContents(props: ScreenContentsProps) {
  return <Container maxWidth="sm" {...props} />;
}

type Props = {
  title: string;
  children: React.ReactNode;
  headerAction?: React.ReactNode;
  showBack?: boolean;
  showNotifications?: boolean;
};

export default function Screen({
  title,
  children,
  headerAction,
  showBack,
  showNotifications,
}: Props) {
  return (
    <Box sx={{ pb: 4 }}>
      <ScreenHeader
        action={headerAction}
        enableBackNavigation={showBack}
        hideNotifications={!showNotifications}
      >
        {title}
      </ScreenHeader>
      <ScreenContents>{children}</ScreenContents>
    </Box>
  );
}
