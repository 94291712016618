import {
  Dialog,
  DialogActions,
  DialogContent,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DeleteVisitorDocument } from "../DeleteVisitor.generated";
import useApolloErrorHandler from "../lib/handleApolloError";
import { PrimaryButton, SecondaryButton } from "../lib/ModalButtons";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  visitorId: string;
};

export default function DeleteVisitorDialog({
  isOpen,
  onClose,
  visitorId,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarContext = useSnackbarContext();
  const handleApolloError = useApolloErrorHandler();

  const [deleteVisitor, { loading }] = useMutation(DeleteVisitorDocument, {
    onError: (e) => handleApolloError(e),
    onCompleted: () => {
      navigate("/toolbox/visitors");
      snackbarContext.alert(
        "info",
        t("This visitor's account has been deleted")
      );
    },
  });

  if (!isOpen) return null;

  return (
    <Dialog title={t("Delete account")} onClose={onClose}>
      <DialogContent>
        <Typography variant="body1" color="text.primary">
          {t(
            "Before proceeding, please ensure you have verified the following:"
          )}
        </Typography>
        <Box my={2}>
          <List sx={{ listStyleType: "disc", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
              {t(
                "The request has come directly from the account owner themselves"
              )}
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              {t(
                "The account owner is unable to perform this action themselves"
              )}
            </ListItem>
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          color="error"
          onClick={() => {
            deleteVisitor({
              variables: {
                input: { visitorId },
              },
            });
          }}
          disabled={loading}
        >
          {t("Delete account")}
        </SecondaryButton>
        <PrimaryButton onClick={onClose} disabled={loading}>
          {t("Cancel")}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
