import { SelectInput, SubmitButton, TextInput } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Stack } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OrganizationType, UsState } from "../api/graphql";
import useApolloErrorHandler from "../lib/handleApolloError";
import ImageInput from "../lib/ImageInput";
import Screen from "../lib/Screen";
import useFileUpload from "../lib/useFileUpload";
import USState from "../lib/USState.enum";
import { isEmail, MAX_PHONE_NUMBER_LENGTH } from "../lib/validations";
import { CreateOrganizationDocument } from "./CreateOrganization.generated";
import Logo from "./Logo.svg";

type FormData = {
  name: string;
  type: OrganizationType;
  description: string;
  websiteLink?: string;
  addressLineOne: string;
  addressLineTwo?: string;
  city: string;
  state: UsState;
  province: string;
  country: string;
  postal: string;
  phone: string;
  email: string;
  logoImage: File;
};

export default function CreateOrganizationScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleApolloError = useApolloErrorHandler();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      country: "US",
    },
  });

  const [createOrganization] = useMutation(CreateOrganizationDocument, {
    onError: (e) => handleApolloError(e),
  });

  const upload = useFileUpload();

  const countryValue = watch("country");

  const inferProvince = (formData: FormData) =>
    formData.country === "US"
      ? { province: USState[formData.state] }
      : { province: formData.province, state: UsState.Ak };

  const onSubmit = async (data: FormData) => {
    const { logoImage, ...remainingData } = data;
    const logoImageKey = await upload(logoImage);
    await createOrganization({
      variables: {
        input: { ...remainingData, logoImageKey, ...inferProvince(data) },
      },
      onCompleted: () => navigate("/toolbox/organizations"),
    });
  };

  return (
    <Screen title={t("Create organization")}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} pb={2}>
          <TextInput
            control={control}
            name="name"
            label={t("Name")}
            rules={{
              required: {
                value: true,
                message: t("Field is required"),
              },
            }}
          />
          <SelectInput
            control={control}
            name="type"
            label={t("Type")}
            rules={{
              required: {
                value: true,
                message: t("Field is required"),
              },
            }}
            items={Object.values(OrganizationType).map((v) => ({
              value: v,
              name: v,
            }))}
          />
          <TextInput
            control={control}
            name="description"
            label={t("Description")}
            rules={{
              required: {
                value: true,
                message: t("Field is required"),
              },
            }}
            multiline
            minRows={5}
            maxRows={10}
          />
          <ImageInput
            control={control}
            name="logoImage"
            label={t("Logo")}
            rules={{ validate: (v) => !!v }}
            placeholderImage={Logo}
            placeholderImageWidth={48}
          />
          <fieldset>
            <legend>{t("Address")}</legend>
            <Stack spacing={2}>
              <TextInput
                control={control}
                name="addressLineOne"
                label={t("Line One")}
                rules={{ required: true }}
              />
              <TextInput
                control={control}
                name="addressLineTwo"
                label={t("Line Two")}
                rules={{ required: false }}
              />
              <TextInput
                control={control}
                name="city"
                label={t("City")}
                rules={{ required: true }}
              />
              <TextInput
                control={control}
                helperText="Insert 2-char country ISO code (e.g. US, PK, IT)"
                name="country"
                label="Country"
                inputProps={{ maxLength: 2 }}
                rules={{ required: true }}
              />
              {countryValue === "US" ? (
                <SelectInput
                  control={control}
                  name="state"
                  label="State"
                  rules={{ required: true }}
                  items={Object.values(UsState).map((v) => ({
                    value: v,
                    name: v,
                  }))}
                />
              ) : (
                <TextInput
                  control={control}
                  name="province"
                  label="Province"
                  rules={{ required: true }}
                />
              )}
              <TextInput
                control={control}
                name="postal"
                label={t("ZIP code")}
                rules={{ required: true }}
              />
            </Stack>
          </fieldset>
          <fieldset>
            <legend>{t("Contact")}</legend>
            <Stack spacing={2}>
              <TextInput
                control={control}
                name="phone"
                label={t("Phone number")}
                inputProps={{ maxLength: MAX_PHONE_NUMBER_LENGTH }}
                rules={{
                  required: {
                    value: true,
                    message: t("Phone number is required"),
                  },
                  maxLength: {
                    value: MAX_PHONE_NUMBER_LENGTH,
                    message: t(
                      "Phone number cannot be more than {{number}} digits",
                      {
                        // {{number}}, not {{count}}: is always > 1
                        number: MAX_PHONE_NUMBER_LENGTH,
                      }
                    ),
                  },
                }}
              />
              <TextInput
                control={control}
                name="email"
                label={t("Email Address")}
                rules={{
                  validate: (v) =>
                    isEmail(v) || t("Please enter a valid email address"),
                }}
              />
              <TextInput
                control={control}
                helperText={t("If included, must be a valid URL")}
                name="websiteLink"
                label={t("Website URL")}
                rules={{ required: false }}
              />
            </Stack>
          </fieldset>
          <SubmitButton submitting={isSubmitting}>{t("Submit")}</SubmitButton>
        </Stack>
      </form>
    </Screen>
  );
}
