import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import NotFoundScreen from "./404";
import { AuthenticationRoutes } from "./Authentication";
import CallRoutes from "./Call/routes";
import { ContactsRoutes } from "./Contacts";
import EventsRoutes from "./Events/routes";
import { HomeRoutes } from "./Home";
import { MessagesRoutes } from "./Messages";
import { NotificationsRoutes } from "./Notifications";
import { OrganizationsRoutes } from "./Organization";
import SessionBoundary from "./SessionBoundary";
import { SettingsRoutes } from "./Settings";
import { SupportRoutes } from "./Support";
import { ToolboxRoutes } from "./Toolbox";
import TwilioCallRoutes from "./TwilioCall/routes";
import VoiceCallRoutes from "./VoiceCall/routes";

const appRoutes: RouteObject[] = [
  {
    path: "*",
    element: <NotFoundScreen />,
  },
  HomeRoutes,
  AuthenticationRoutes,
  SettingsRoutes,
  OrganizationsRoutes,
  EventsRoutes,
  MessagesRoutes,
  CallRoutes,
  TwilioCallRoutes,
  VoiceCallRoutes,
  ContactsRoutes,
  NotificationsRoutes,
  SupportRoutes,
  {
    element: (
      <SessionBoundary>
        <Outlet />
      </SessionBoundary>
    ),
    children: [ToolboxRoutes],
  },
];

export default appRoutes;
