import { SelectInput, SubmitButton, TextInput } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Stack } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import timezonesList from "timezones-list";
import { FacilityType, UsState } from "../api/graphql";
import { AvailableCountry } from "../lib/DocumentType";
import useApolloErrorHandler from "../lib/handleApolloError";
import Screen from "../lib/Screen";
import USState from "../lib/USState.enum";
import { CreateFacilityDocument } from "./CreateFacility.generated";

type FormData = {
  name: string;
  publicId: string;
  type: FacilityType;
  city: string;
  state: UsState;
  country: string;
  province: string;
  postal: string;
  timezone: string;
};

export default function CreateFacilityScreen() {
  const { systemId } = useParams<{ systemId: string }>();
  if (!systemId) throw new Error("Missing system id");

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      country: "US",
    },
  });

  const countryValue = watch("country");

  const handleApolloError = useApolloErrorHandler();
  const [createFacility] = useMutation(CreateFacilityDocument, {
    onError: (e) => handleApolloError(e),
  });

  const inferProvince = (formData: FormData) =>
    formData.country === "US"
      ? { province: USState[formData.state] }
      : { province: formData.province, state: UsState.Ak };

  const onSubmit = async (data: FormData) => {
    await createFacility({
      variables: {
        input: { ...data, systemId, ...inferProvince(data) },
      },
      onCompleted: () => navigate(`/toolbox/systems/${systemId}`),
    });
  };

  return (
    <Screen title="Create Facility">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <TextInput
            control={control}
            name="name"
            label="Name"
            helperText="May be edited by staff"
            rules={{ required: true }}
          />
          <TextInput
            control={control}
            name="publicId"
            label="Abbrevation"
            helperText="Seen by visitors and referenced by facility resident rosters"
            rules={{ required: true }}
          />
          <SelectInput
            control={control}
            name="type"
            label="Type"
            rules={{ required: true }}
            items={Object.values(FacilityType).map((v) => ({
              value: v,
              name: v,
            }))}
          />
          <SelectInput
            control={control}
            name="timezone"
            label="Time zone"
            rules={{ required: true }}
            items={timezonesList.map((tz) => ({
              value: tz.tzCode,
              name: `GMT(${tz.utc}) ${tz.tzCode}`,
            }))}
          />
          <Stack component="fieldset" spacing={2}>
            <legend>Partial address</legend>
            <TextInput
              control={control}
              name="city"
              label="City"
              rules={{ required: true }}
            />
            <TextInput
              control={control}
              helperText="Insert 2-char country ISO code (e.g. US, PK, IT)"
              name="country"
              label="Country"
              inputProps={{ maxLength: 2 }}
              rules={{ required: true }}
            />
            {countryValue === AvailableCountry.US ? (
              <SelectInput
                control={control}
                name="state"
                label="State"
                rules={{ required: true }}
                items={Object.values(UsState).map((v) => ({
                  value: v,
                  name: v,
                }))}
              />
            ) : (
              <TextInput
                control={control}
                name="province"
                label="Province"
                rules={{ required: true }}
              />
            )}
            <TextInput
              control={control}
              name="postal"
              label={
                countryValue === AvailableCountry.US
                  ? "ZIP code"
                  : "Postal code"
              }
              rules={{ required: true }}
            />
          </Stack>
          <SubmitButton submitting={isSubmitting}>Submit</SubmitButton>
        </Stack>
      </form>
    </Screen>
  );
}
