import { Dot } from "@ameelio/ui";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useRef } from "react";
import { BaseSkeleton, IconSkeleton } from "../lib/closet";
import { microElapsedTimeString } from "../lib/timeFormats";
import NotificationEventType from "./NotificationEventType";
import useNotificationContent from "./useNotificationContent";

const verticallyCentered: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const notifContentStyle: SxProps<Theme> = {
  minWidth: 0,
  width: 1,
  whiteSpace: "break-spaces",
  wordBreak: "break-word",
};

type Props = {
  icon: React.ReactNode;
  isRead: boolean;
  created: Date | number;
  index: number;
  type: NotificationEventType;
  params: string;
  setHeight: (index: number, height: number) => void;
  onClick?: () => void;
};

export function Notification({
  icon,
  isRead,
  created,
  onClick,
  setHeight,
  index,
  type,
  params,
}: Props) {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const currentHeight = ref.current?.getBoundingClientRect().height || 0;
    if (currentHeight > 0) {
      setHeight(index, currentHeight);
    }
  }, [setHeight, index]);

  const { title: notificationTitle, body: notificationBody } =
    useNotificationContent(type, params);

  if (!notificationTitle && !notificationBody) {
    return null;
  }

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        py: 1,
        px: 2,
        cursor: "pointer",
        backgroundColor: isRead ? "white" : grey[50],
      }}
      onClick={onClick}
    >
      <Box sx={verticallyCentered} role="img">
        {icon}
      </Box>
      <Box
        sx={{
          ...notifContentStyle,
        }}
        pl={2}
        pr={0.5}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {notificationTitle}
        </Typography>
        <Typography variant="body1">{notificationBody}</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", py: 0.5 }}>
        <Box>
          <Typography variant="body2" color="text.secondary">
            {microElapsedTimeString(created)}
          </Typography>
        </Box>
        <Box sx={{ ...verticallyCentered, flex: 1 }}>
          <Box sx={{ ml: "auto" }}>{!isRead && <Dot />}</Box>
        </Box>
      </Box>
    </Box>
  );
}

export function NotificationSkeleton() {
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ ...verticallyCentered, pl: 2 }}>
        <IconSkeleton />
      </Box>
      <Box sx={{ ...notifContentStyle, p: 2 }}>
        <BaseSkeleton variant="text" sx={{ width: 1 }} />
        <BaseSkeleton variant="text" sx={{ width: 1, height: 50 }} />
      </Box>
    </Box>
  );
}
