import { ConnectCall, Role } from "@ameelio/connect-call-client";
import { Avatar, IconButton, TextInput } from "@ameelio/ui";
import { LockOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  Alert,
  alpha,
  Box,
  Grow,
  Stack,
  Typography,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSound from "use-sound";
import { Correspondent, PrivacyLevel } from "../../api/graphql";
import addStylesForDevices from "../../lib/addStylesForDevices";
import MicroElapsedTime from "../../lib/MicroElapsedTime";
import { belowLargeTablet } from "../../lib/responsiveHelpers";
import TruncatedTypography from "../../lib/TruncatedTypography";
import useScrolledToBottom from "../../lib/useScrolledToBottom";
import withSx from "../../lib/withSx";
import chatSendSound from "./sounds/chat_send.mp3";

const ChatBubble = withSx(Box, {
  flexGrow: { xs: 0.25, md: 0.75 },
  borderRadius: 4,
  overflow: "hidden",
  p: 1.5,
});

const ChatHeader = withSx(Box, {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  mb: 1,
});

type ChatMessageProps = {
  correspondent: Pick<
    Correspondent,
    "id" | "firstName" | "lastName" | "fullName"
  >;
  currentUserId: string;
  contents: string;
  timestamp: Date;
};

function ChatMessage({
  currentUserId,
  correspondent: user,
  contents,
  timestamp,
}: ChatMessageProps) {
  const { t } = useTranslation();
  const from = user.id === currentUserId ? "me" : "them";
  return (
    <ChatBubble
      sx={{
        background: (theme) =>
          from === "me" ? alpha(theme.palette.primary.main, 0.3) : grey[200],
      }}
    >
      <ChatHeader>
        <Avatar contact={user} sx={{ fontSize: 12, height: 20, width: 20 }} />
        <TruncatedTypography
          variant="caption"
          color="text.primary"
          sx={{ ml: 0.5, flexGrow: 1 }}
        >
          {user.fullName}
          {from === "me" && ` (${t("you")})`}
        </TruncatedTypography>
        <Typography variant="caption" color="text.secondary">
          <MicroElapsedTime timestamp={timestamp} />
        </Typography>
      </ChatHeader>
      <Typography variant="body2" color="text.primary">
        {contents}
      </Typography>
    </ChatBubble>
  );
}

type AlertMessageProps = {
  facilityName: string;
  contents: string;
  timestamp: Date;
};

function AlertMessage({
  facilityName,
  contents,
  timestamp,
}: AlertMessageProps) {
  const { t } = useTranslation();
  return (
    <ChatBubble sx={{ background: grey[200] }}>
      <ChatHeader>
        <WarningAmberRoundedIcon sx={{ color: grey[800] }} />
        <TruncatedTypography
          variant="caption"
          color="text.primary"
          sx={{ ml: 0.5, flexGrow: 1, textTransform: "uppercase" }}
        >
          {t(`{{facilityName}} Staff`, { facilityName })}
        </TruncatedTypography>
        <Typography variant="caption" color="text.secondary">
          <MicroElapsedTime timestamp={timestamp} />
        </Typography>
      </ChatHeader>
      <Typography variant="body2" color="text.primary">
        {contents}
      </Typography>
    </ChatBubble>
  );
}

type Props = {
  facilityName: string;
  privacyLevel: PrivacyLevel;
  currentUserId: string;
  correspondents: ChatMessageProps["correspondent"][];
  messages: ConnectCall["messages"];
  onSend?: (message: string) => void;
  onClose: () => void;
  muteSounds?: boolean;
};

type FormData = {
  message: string;
};

export default function CallChat({
  facilityName,
  privacyLevel,
  currentUserId,
  correspondents,
  messages,
  onSend,
  onClose,
  muteSounds,
}: Props) {
  const { t } = useTranslation();
  const [playMessageSent] = useSound(chatSendSound, { volume: 0.5 });
  const { control, watch, handleSubmit, reset } = useForm<FormData>({
    mode: "onTouched",
  });
  const messageContent = watch("message");
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);

  const onSubmit = async (data: FormData) => {
    if (!onSend || !data.message || data.message.trim().length === 0) return;
    onSend(data.message.trim());
    if (!muteSounds) playMessageSent();
    reset();
  };

  const { scrolledToBottom, onScroll } = useScrolledToBottom(true);

  const sendDisabled = !onSend || (messageContent || "").trim().length === 0;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: 1 }}>
      <Box sx={{ display: "flex", padding: 2 }}>
        <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
          {t("In-call chat")}
        </Typography>
        <IconButton size="small" ariaLabel={t("Close")} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "scroll",
          px: 2,
        }}
        onScroll={onScroll}
      >
        {privacyLevel === PrivacyLevel.Monitored && (
          <Alert
            severity="info"
            icon={<RemoveRedEyeOutlined />}
            sx={{ justifyContent: "center" }}
          >
            {onSend
              ? t(
                  "Messages can be seen by security staff. They will not be accessible to you when the call ends."
                )
              : t("Messages will be available once the call is live")}
          </Alert>
        )}
        {privacyLevel !== PrivacyLevel.Monitored && (
          <Alert
            severity="info"
            icon={<LockOutlined />}
            sx={{ justifyContent: "center" }}
          >
            {onSend
              ? t(
                  "Messages cannot be seen by security staff. They will not be accessible to you when the call ends."
                )
              : t("Messages will be available once the call is live")}
          </Alert>
        )}
        <Stack mt={2} gap={1}>
          {messages.map((m) => {
            // lookups are risky. i don't know how to avoid this one yet.
            const correspondent = correspondents.find(
              (p) => p.id === m.user.id
            ) || {
              id: "",
              firstName: "",
              lastName: "",
              fullName: "",
            };
            return (
              <Grow
                in
                timeout={500}
                style={{ transformOrigin: "bottom center" }}
                key={m.timestamp.getTime()}
              >
                <Box
                  sx={[
                    {
                      display: "flex",
                    },
                    m.user.role === Role.monitor
                      ? {}
                      : {
                          [currentUserId === correspondent.id ? "ml" : "mr"]: 2,
                          justifyContent:
                            currentUserId === correspondent.id
                              ? "flex-end"
                              : "flex-start",
                        },
                  ]}
                >
                  {m.user.role === Role.monitor ? (
                    <AlertMessage
                      facilityName={facilityName}
                      contents={m.contents}
                      timestamp={m.timestamp}
                    />
                  ) : (
                    <ChatMessage
                      currentUserId={currentUserId}
                      correspondent={correspondent}
                      contents={m.contents}
                      timestamp={m.timestamp}
                    />
                  )}
                </Box>
              </Grow>
            );
          })}
        </Stack>
        {scrolledToBottom && (
          <div
            ref={(node) =>
              node?.scrollIntoView({
                behavior: "auto",
                block: "start",
              })
            }
          />
        )}
      </Box>
      <Box sx={{ display: "flex", padding: 2 }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "contents" }}>
          <Box sx={{ flexGrow: 1, pt: 1 }}>
            <TextInput
              control={control}
              name="message"
              label={t("Message")}
              autoComplete="off"
              sx={addStylesForDevices(
                isMobileOrSmallTablet,
                { flexGrow: 1 },
                { width: 1 }
              )}
              disabled={!onSend}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              pt: 1,
            }}
          >
            <IconButton
              tooltip
              size="large"
              type="submit"
              ariaLabel={sendDisabled ? t("Sending is disabled") : t("Send")}
              disabled={sendDisabled}
            >
              <SendIcon color={sendDisabled ? "disabled" : "info"} />
            </IconButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
