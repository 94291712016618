import { Button, TextInputBase } from "@ameelio/ui";
import { Box } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Screen from "../lib/Screen";
import { SearchQuery } from "./types";
import VisitorsTable from "./VisitorsTable";

type Props = {
  visitors: {
    __typename: "Visitor";
    id: string;
    fullName: string;
    email: string;
  }[];
  defaultSearch: SearchQuery;
  loading: boolean;
  onUpdateSearch: (value: SearchQuery) => void;
};

export default function Visitors({
  visitors,
  defaultSearch,
  loading,
  onUpdateSearch,
}: Props) {
  const { t } = useTranslation();

  // search filters are restored from page state for back navigation, so
  // set the state value here to the default search terms passed down
  const [searchQuery, setSearchQuery] = useState<SearchQuery>(defaultSearch);
  const onSearch = useCallback(() => {
    onUpdateSearch({
      name: searchQuery.name?.trim() || undefined,
      email: searchQuery.email?.trim() || undefined,
    });
  }, [onUpdateSearch, searchQuery.email, searchQuery.name]);

  return (
    <Screen title={t("Visitors")}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={2}
        mb={3}
      >
        <TextInputBase
          size="small"
          sx={{
            borderRadius: "8px",
          }}
          autoComplete="off"
          value={searchQuery.name}
          label={t("Name")}
          onChange={(e) => {
            setSearchQuery({ ...searchQuery, name: e.target.value });
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") onSearch();
          }}
          addClearIcon
          onClear={() => {
            setSearchQuery({ ...searchQuery, name: undefined });
            onUpdateSearch({ ...searchQuery, name: undefined });
          }}
        />
        <TextInputBase
          size="small"
          sx={{
            borderRadius: "8px",
          }}
          autoComplete="off"
          value={searchQuery.email}
          label={t("Email address")}
          onChange={(e) => {
            setSearchQuery({ ...searchQuery, email: e.target.value });
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") onSearch();
          }}
          addClearIcon
          onClear={() => {
            setSearchQuery({ ...searchQuery, email: undefined });
            onUpdateSearch({ ...searchQuery, email: undefined });
          }}
        />
        <Button variant="contained" onClick={onSearch}>
          {t("Search")}
        </Button>
      </Box>
      <VisitorsTable
        visitors={visitors}
        filters={searchQuery}
        loading={loading}
      />
    </Screen>
  );
}
