import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContentSkeleton } from "../lib/closet";
import Link from "../lib/Link";
import Screen from "../lib/Screen";
import { GetOrganizationsDocument } from "./GetOrganizations.generated";

export default function OrganizationsScreen() {
  const { t } = useTranslation();
  const { data, error } = useQuery(GetOrganizationsDocument, {
    fetchPolicy: "cache-and-network",
  });

  if (error) throw error;
  if (!data) return <ContentSkeleton />;

  const organizations = data.organizations.edges.map((e) => e.node);

  return (
    <Screen
      headerAction={
        <Link
          id="systems-screen-split"
          button
          variant="outlined"
          to="/toolbox/organizations/new"
          sx={{ flexGrow: 1 }}
        >
          Add organization
        </Link>
      }
      title={t("Organizations")}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((o) => (
              <TableRow key={o.id}>
                <TableCell>{o.name}</TableCell>
                <TableCell>{o.type}</TableCell>
                <TableCell>
                  <Link to={`/toolbox/organizations/${o.id}/partnerships`}>
                    {t("Partners")}
                  </Link>
                  {" • "}
                  <Link to={`/toolbox/organizations/${o.id}/members`}>
                    {t("Members")}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Screen>
  );
}
