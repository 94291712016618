import { Chip, ContextMenu } from "@ameelio/ui";
import LockOutlined from "@mui/icons-material/LockOutlined";
import {
  Box,
  Card,
  MenuItem,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { User } from "@sentry/react";
import { isToday, isTomorrow, isWithinInterval, subMinutes } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Connection,
  Correspondent,
  Interval,
  Meeting,
  MeetingStatus,
  MeetingType,
  PrivacyLevel,
  Visitor,
} from "../api/graphql";
import isMultimediaMeeting from "../lib/isMultimediaMeeting";
import Link from "../lib/Link";
import MeetingStatusChip from "../lib/MeetingStatusChip";
import MeetingTypeIcon from "../lib/MeetingTypeIcon";
import { participantsList } from "../lib/nameUtils";
import { formatTimeRange, shortTime, yearMonthDate } from "../lib/timeFormats";
import { useCurrentCorrespondent } from "../SessionBoundary";
import JoinCallButton from "./JoinCallButton";

export const LEAVABLE_MEETING_STATUSES = [
  MeetingStatus.PendingApproval,
  MeetingStatus.Scheduled,
];

export type MeetingEvent = Pick<
  Meeting,
  | "id"
  | "title"
  | "meetingType"
  | "status"
  | "unregisteredGuests"
  | "privacyLevel"
> & {
  interval: Pick<Interval, "startAt" | "endAt">;
  correspondents: Pick<
    Correspondent,
    "id" | "firstName" | "lastName" | "fullName"
  >[];
  connections: (Pick<Connection, "id"> & { visitor: Pick<User, "id"> })[];
  primaryVisitor?: Pick<Visitor, "id"> | null;
  visitors: Pick<Visitor, "id">[];
};

type Props = {
  meeting: MeetingEvent;
  onCancel: (args: { description: string; meetingId: string }) => void;
  onLeaveEvent: (args: { description: string; meetingId: string }) => void;
  sx?: SxProps<Theme>;
  showDateInfo?: boolean;
};

export function isCallJoinable(
  meeting: Pick<Meeting, "meetingType" | "status"> & {
    interval: Pick<Interval, "startAt" | "endAt">;
  }
) {
  const now = new Date();
  const inJoinableWindow =
    isMultimediaMeeting(meeting.meetingType) &&
    isWithinInterval(now, {
      start: subMinutes(meeting.interval.startAt, 5),
      end: meeting.interval.endAt,
    });

  return (
    inJoinableWindow &&
    isMultimediaMeeting(meeting.meetingType) &&
    [MeetingStatus.Live, MeetingStatus.Scheduled].includes(meeting.status)
  );
}

export default function EventCard({
  meeting,
  onCancel,
  onLeaveEvent,
  sx,
  showDateInfo,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useCurrentCorrespondent();
  const currentUserIsPrimaryVisitor =
    currentUser.id === meeting.primaryVisitor?.id;
  const now = new Date();
  const isEditable =
    [MeetingStatus.PendingApproval, MeetingStatus.Scheduled].includes(
      meeting.status
    ) && subMinutes(meeting.interval.startAt, 5) > now;
  const isWebinar = meeting.meetingType === MeetingType.Webinar;
  const isWebinarHost = isWebinar && currentUser.__typename === "Visitor";
  // All events must be in a cancelable state, only webinar hosts can cancel
  // webinars, but inmates or visitors can cancel 1-on-1 meetings
  const canCancelEvent =
    isEditable &&
    (isWebinarHost ||
      (!isWebinar &&
        (currentUserIsPrimaryVisitor || currentUser.__typename === "Inmate")));
  // anyone who can cancel can also modify except inmates
  const canModifyEvent = canCancelEvent && currentUser.__typename !== "Inmate";

  // Parsing participants
  const registeredParticipantsList = meeting.correspondents.filter(
    (c) => c.id !== currentUser.id
  );
  const unregisteredParticipantsList = meeting.unregisteredGuests || [];
  const allParticipantsList = [
    ...registeredParticipantsList.map((p) => p.fullName),
    ...unregisteredParticipantsList,
  ];

  // Primary contact name is the primary visitor for the inmate, or the first participant for the visitor
  const primaryContactName =
    currentUser.__typename === "Inmate" && meeting.primaryVisitor
      ? registeredParticipantsList.find(
          (c) => c.id === meeting.primaryVisitor?.id
        )?.fullName
      : allParticipantsList[0];
  const otherParticipants = primaryContactName
    ? allParticipantsList.filter((c) => c !== primaryContactName)
    : null;

  const meetingName =
    meeting.title || primaryContactName || allParticipantsList.join(", ");

  // Set the cancel meeting dialog description (if needed)
  const cancelDescription = isWebinar
    ? t(
        "This can't be undone. We will let all hosts and students know that the webinar is canceled."
      )
    : t(
        "This can't be undone. We will let {{participants}} know that the meeting is canceled.",
        { participants: participantsList(registeredParticipantsList) }
      );

  // depends on re-renders to re-evaluate. this currently
  // works because we poll for new events.

  return (
    <Card variant="outlined" sx={sx || {}}>
      <Stack padding={2} spacing={1.5}>
        <Box display="flex" justifyContent="space-between">
          <Stack
            direction="row"
            spacing={1}
            color="primary.main"
            alignItems="center"
          >
            <MeetingTypeIcon meetingType={meeting.meetingType} />
            <Link to={`/events/${meeting.id}`}>
              <Typography
                marginLeft={1}
                variant="h3"
                sx={{ textDecoration: "underline" }}
              >
                {meetingName}
              </Typography>
            </Link>
            {meeting.status === MeetingStatus.PendingApproval && (
              <Tooltip
                arrow
                placement="top"
                title={t(
                  "This event is being reviewed right now. This can take up to 3 business days."
                )}
              >
                <span>
                  <MeetingStatusChip
                    status={meeting.status}
                    meetingType={meeting.meetingType}
                  />
                </span>
              </Tooltip>
            )}
            {meeting.status === MeetingStatus.Rejected && (
              <Tooltip
                arrow
                placement="top"
                title={t(
                  "This event was declined by the facility. You should have received an email with the reason why the event was declined."
                )}
              >
                <span>
                  <MeetingStatusChip
                    status={meeting.status}
                    meetingType={meeting.meetingType}
                  />
                </span>
              </Tooltip>
            )}
            {[
              MeetingStatus.Cancelled,
              MeetingStatus.Ended,
              MeetingStatus.NoShow,
              MeetingStatus.Terminated,
            ].includes(meeting.status) && (
              <span>
                <MeetingStatusChip
                  status={meeting.status}
                  meetingType={meeting.meetingType}
                />
              </span>
            )}
            {showDateInfo &&
              meeting.status === MeetingStatus.Scheduled &&
              (isToday(meeting.interval.startAt) ? (
                <Chip color="green" label={t("Today")} />
              ) : isTomorrow(meeting.interval.startAt) ? (
                <Chip color="blue" label={t("Tomorrow")} />
              ) : null)}
          </Stack>

          <ContextMenu id={`meeting-${meeting.id}`}>
            <MenuItem onClick={() => navigate(`/events/${meeting.id}`)}>
              {t("See event details")}
            </MenuItem>
            {canModifyEvent && (
              <MenuItem
                onClick={() =>
                  navigate("/events/new", {
                    state: {
                      fromPath: "/events",
                      fromName: t("Events"),
                      action: "edit",
                      meetingId: meeting.id,
                      connectionId:
                        meeting.meetingType !== MeetingType.Webinar
                          ? meeting.connections.find(
                              (c) => c.visitor.id === currentUser.id
                            )?.id
                          : undefined,
                    },
                  })
                }
              >
                {t("Modify event")}
              </MenuItem>
            )}
            {isWebinarHost && (
              <MenuItem
                onClick={() =>
                  navigate("/events/new", {
                    state: {
                      fromPath: "/events",
                      fromName: t("Events"),
                      action: "duplicate",
                      meetingId: meeting.id,
                    },
                  })
                }
              >
                {t("Duplicate event")}
              </MenuItem>
            )}
            {canCancelEvent && (
              <MenuItem
                onClick={() =>
                  onCancel({
                    description: cancelDescription,
                    meetingId: meeting.id,
                  })
                }
                sx={{ color: "error.main" }}
              >
                {t("Cancel event")}
              </MenuItem>
            )}
            {!currentUserIsPrimaryVisitor &&
              currentUser.__typename === "Visitor" &&
              LEAVABLE_MEETING_STATUSES.includes(meeting.status) && (
                <MenuItem
                  onClick={() =>
                    onLeaveEvent({
                      description: t("This can't be undone."),
                      meetingId: meeting.id,
                    })
                  }
                  sx={{ color: "error.main" }}
                >
                  {t("Leave event")}
                </MenuItem>
              )}
          </ContextMenu>
        </Box>
        {meeting.privacyLevel !== PrivacyLevel.Monitored && (
          <Box display="flex" flexDirection="row" gap={2} pb={1}>
            <Tooltip
              title={
                meeting.meetingType === MeetingType.InPersonVisit
                  ? t("This visit is confidential and cannot be monitored.")
                  : t(
                      "This call is confidential. It cannot be recorded, but low-quality screenshots are taken at random times for staff to review."
                    )
              }
            >
              <LockOutlined />
            </Tooltip>
            <Typography variant="body1" color="text.primary">
              {t("Confidential")}
            </Typography>
          </Box>
        )}
        {isWebinarHost ? (
          <Typography variant="body2" color="text.secondary">
            {t("{{count}} participants", {
              count: meeting.correspondents.length,
            })}
          </Typography>
        ) : otherParticipants?.length ? (
          <Typography variant="body2" color="text.secondary">
            {otherParticipants.join(", ")}
          </Typography>
        ) : null}
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            textDecoration: [
              MeetingStatus.Cancelled,
              MeetingStatus.Rejected,
            ].includes(meeting.status)
              ? "line-through"
              : undefined,
          }}
        >
          {showDateInfo ? `${yearMonthDate(meeting.interval.startAt)} ` : ""}
          {
            // Voice calls that are "scheduled" are in a calling phase
            // and voice calls that are "no show" were not answered.
            // In both cases, displaying an end time doesn't make sense.
            meeting.meetingType === MeetingType.VoiceCall &&
            [MeetingStatus.Scheduled, MeetingStatus.NoShow].includes(
              meeting.status
            )
              ? shortTime.format(meeting.interval.startAt)
              : formatTimeRange(
                  meeting.interval.startAt,
                  meeting.interval.endAt
                )
          }
        </Typography>
        {(isWebinar ||
          currentUserIsPrimaryVisitor ||
          currentUser.__typename === "Inmate") &&
          isCallJoinable(meeting) && <JoinCallButton meeting={meeting} />}
      </Stack>
    </Card>
  );
}
