import { Box, Stack, useMediaQuery as measureScreenWidth } from "@mui/material";
import React, { ReactNode } from "react";
import FullscreenBox from "../FullscreenBox";
import addStylesForDevices from "./addStylesForDevices";
import Link from "./Link";
import ResetScroll from "./ResetScroll";
import { onlyMobile } from "./responsiveHelpers";
import { ScreenTitle } from "./typography";

type Props = {
  title: string;
  content: ReactNode;
  buttonText: string;
  buttonPath: string;
  illustration: string;
  illustrationWidth: number;
};

export default function SuccessScreen({
  title,
  content,
  buttonText,
  buttonPath,
  illustration,
  illustrationWidth,
}: Props) {
  const mobileOnly = onlyMobile(measureScreenWidth);

  return (
    <FullscreenBox
      sx={{
        backgroundColor: "#FAFAFA",
      }}
    >
      <ResetScroll />
      <Stack
        spacing={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          maxWidth: "100vw",
          overflowX: "hidden",
          p: 2,
        }}
      >
        <img src={illustration} alt="" width={illustrationWidth} />
        <Stack spacing={2}>
          <ScreenTitle style={{ textAlign: "center" }}>{title}</ScreenTitle>
          <Box
            sx={{
              textAlign: "center",
              marginTop: 2,
              maxWidth: 600,
            }}
          >
            {content}
          </Box>
        </Stack>
        <Stack
          spacing={2}
          sx={addStylesForDevices(
            mobileOnly,
            { textAlign: "center" },
            { width: 1 }
          )}
        >
          <Link
            button
            to={buttonPath}
            variant="contained"
            sx={addStylesForDevices(mobileOnly, {}, { width: 1 })}
          >
            {buttonText}
          </Link>
        </Stack>
      </Stack>
    </FullscreenBox>
  );
}
