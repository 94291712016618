import { notMissing } from "@ameelio/core";
import { Divider, Tab, Tabs } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { CorrespondentFeature, MeetingStatus } from "../api/graphql";
import buildPageTitle from "../lib/buildPageTitle";
import { ListSkeleton } from "../lib/closet";
import featureEnabled from "../lib/featureEnabled";
import Link from "../lib/Link";
import Screen from "../lib/Screen";
import { ScreenSectionTitle } from "../lib/ScreenSection";
import SplitButton from "../lib/SplitButton";
import useFeaturePermitted from "../lib/useFeaturePermitted";
import useHasActiveContact from "../lib/useHasActiveContact";
import useMayScheduleWebinars from "../lib/useMayScheduleWebinars";
import { useCurrentCorrespondent } from "../SessionBoundary";
import CancelMeetingDialog from "./CancelMeetingDialog";
import { MeetingEvent } from "./EventCard";
import EventList from "./EventList";
import LeaveMeetingDialog from "./LeaveMeetingDialog";
import NoEventsScreen from "./NoEventsScreen";
import useEventsQuery from "./useEventsQuery";
import UserAlerts from "./UserAlerts";
import { unaccompaniedVisitAllowed } from "./utils";

const filterUpcomingMeetings = (meetings: MeetingEvent[]) =>
  meetings
    .filter((m) =>
      [
        MeetingStatus.PendingApproval,
        MeetingStatus.Scheduled,
        MeetingStatus.Live,
      ].includes(m.status)
    )
    .sort(
      (a, b) =>
        new Date(a.interval.startAt).getTime() -
        new Date(b.interval.startAt).getTime()
    );

const filterPastMeetings = (meetings: MeetingEvent[]) =>
  meetings
    .filter((m) =>
      [
        MeetingStatus.Ended,
        MeetingStatus.Terminated,
        MeetingStatus.NoShow,
      ].includes(m.status)
    )
    .sort(
      (a, b) =>
        new Date(b.interval.startAt).getTime() -
        new Date(a.interval.startAt).getTime()
    );

const filterNotApprovedMeetings = (meetings: MeetingEvent[]) =>
  meetings
    .filter((m) =>
      [MeetingStatus.Cancelled, MeetingStatus.Rejected].includes(m.status)
    )
    .sort(
      (a, b) =>
        new Date(b.interval.startAt).getTime() -
        new Date(a.interval.startAt).getTime()
    );

export default function EventsScreen() {
  const { t } = useTranslation();
  const headerTitle = t("Events");
  const pageTitle = buildPageTitle(headerTitle);
  const user = useCurrentCorrespondent();

  const [activeTab, setActiveTab] = useState<
    "upcoming" | "past" | "notApproved"
  >("upcoming");
  const [meetingToCancel, setMeetingToCancel] = useState<
    { description: string; meetingId: string } | undefined
  >();
  const [meetingToLeave, setMeetingToLeave] = useState<
    { description: string; meetingId: string } | undefined
  >();

  const { data, error, refetch, fetchMore } = useEventsQuery();

  const allMeetings = useMemo(
    () => data?.currentCorrespondent?.meetings.edges.map((e) => e.node) || [],
    [data]
  );

  const upcomingMeetings = useMemo(
    () => filterUpcomingMeetings(allMeetings),
    [allMeetings]
  );

  const pastMeetings = useMemo(
    () => filterPastMeetings(allMeetings),
    [allMeetings]
  );

  const notApprovedMeetings = useMemo(
    () => filterNotApprovedMeetings(allMeetings),
    [allMeetings]
  );

  const fetchOlder = useMemo(() => {
    const pageInfo = data?.currentCorrespondent?.meetings.pageInfo;
    return pageInfo?.hasPreviousPage
      ? () =>
          fetchMore({
            variables: {
              before: pageInfo.startCursor,
            },
          })
      : undefined;
  }, [data, fetchMore]);

  const mayScheduleEvents =
    useFeaturePermitted(CorrespondentFeature.ScheduleMeetings) &&
    unaccompaniedVisitAllowed(user);
  const mayRequestConnections = useFeaturePermitted(
    CorrespondentFeature.RequestConnections
  );
  const mayScheduleWebinars = useMayScheduleWebinars();
  const hasActiveContact = useHasActiveContact();

  const showTabs =
    featureEnabled("HOMEPAGE") &&
    allMeetings.length > 0 &&
    (mayScheduleEvents || mayScheduleWebinars);

  if (error) throw error;
  if (!data?.currentCorrespondent) return <ListSkeleton />;

  return (
    <Screen
      title={headerTitle}
      showNotifications
      headerAction={
        ((mayScheduleEvents || mayScheduleWebinars) && hasActiveContact && (
          <SplitButton
            id="schedule-event-type"
            tooltipTitle={
              !hasActiveContact
                ? t(
                    "Once you have an approved contact you may be able to schedule events"
                  )
                : ""
            }
            buttons={[
              mayScheduleEvents
                ? {
                    label: t("Schedule event"),
                    to: "/events/new",
                    state: { fromPath: "/events", fromName: t("Events") },
                  }
                : undefined,
              mayScheduleWebinars
                ? {
                    label: t("Schedule webinar"),
                    to: "/events/new/webinar",
                    state: { fromPath: "/events", fromName: t("Events") },
                  }
                : undefined,
            ].filter(notMissing)}
            sx={{ flexGrow: 1 }}
          />
        )) ||
        (!hasActiveContact && (
          <Link button variant="contained" to="/contacts" sx={{ flexGrow: 1 }}>
            {t("Manage contacts")}
          </Link>
        ))
      }
    >
      {meetingToCancel && (
        <CancelMeetingDialog
          meetingToCancel={meetingToCancel}
          onClose={() => setMeetingToCancel(undefined)}
        />
      )}
      {meetingToLeave && (
        <LeaveMeetingDialog
          meetingToLeave={meetingToLeave}
          onClose={async () => {
            refetch();
            setMeetingToLeave(undefined);
          }}
        />
      )}
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <UserAlerts />
      {showTabs && (
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          aria-label={t("Event tabs")}
          sx={{ mb: 4 }}
        >
          <Tab label={t("Upcoming")} value="upcoming" />
          <Tab label={t("Past")} value="past" />
          <Tab label={t("Not approved")} value="notApproved" />
        </Tabs>
      )}

      {showTabs ? (
        <>
          {activeTab === "upcoming" && (
            <EventList
              meetings={upcomingMeetings}
              onCancel={setMeetingToCancel}
              onLeaveEvent={setMeetingToLeave}
              showDateInfo
            />
          )}
          {activeTab === "past" && (
            <EventList
              meetings={pastMeetings}
              onCancel={setMeetingToCancel}
              onLeaveEvent={setMeetingToLeave}
              fetchOlder={fetchOlder}
            />
          )}
          {activeTab === "notApproved" && (
            <EventList
              meetings={notApprovedMeetings}
              onCancel={setMeetingToCancel}
              onLeaveEvent={setMeetingToLeave}
            />
          )}
        </>
      ) : (
        <>
          {!!upcomingMeetings.length && (
            <>
              <ScreenSectionTitle title={t("Upcoming")} />
              <EventList
                meetings={upcomingMeetings}
                onCancel={setMeetingToCancel}
                onLeaveEvent={setMeetingToLeave}
              />
            </>
          )}
          {(!!pastMeetings.length || !!notApprovedMeetings.length) && (
            <>
              {!!upcomingMeetings.length && <Divider sx={{ my: 5 }} />}
              <ScreenSectionTitle title={t("History")} />
              <EventList
                meetings={[...pastMeetings, ...notApprovedMeetings].sort(
                  (a, b) =>
                    new Date(b.interval.startAt).getTime() -
                    new Date(a.interval.startAt).getTime()
                )}
                onCancel={setMeetingToCancel}
                onLeaveEvent={setMeetingToLeave}
                fetchOlder={fetchOlder}
              />
            </>
          )}
        </>
      )}

      {!allMeetings.length && (
        <NoEventsScreen
          cta={
            mayScheduleEvents && hasActiveContact
              ? "schedule"
              : mayRequestConnections &&
                  (unaccompaniedVisitAllowed(user) || !hasActiveContact)
                ? "connect"
                : "none"
          }
        />
      )}
    </Screen>
  );
}
