import { sortByDates } from "@ameelio/core";
import { useQuery } from "@apollo/client";
import { Box, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  ConnectionFeature,
  ConnectionStatus,
  CorrespondentFeature,
  FacilityFeature,
  FacilityService,
  MeetingStatus,
} from "../api/graphql";
import SignupSuccess from "../Authentication/SignupSuccess.svg";
import ConnectionCard from "../Contacts/ConnectionCard";
import { GetConnectionsDocument } from "../Contacts/GetConnections.generated";
import { GetContactsScreenDataDocument } from "../Contacts/GetContactsScreenData.generated";
import EmptyScreen from "../EmptyScreen";
import CancelMeetingDialog from "../Events/CancelMeetingDialog";
import EventCard from "../Events/EventCard";
import LeaveMeetingDialog from "../Events/LeaveMeetingDialog";
import { NoContactsScreen } from "../Events/NoEventsScreen";
import useEventsQuery from "../Events/useEventsQuery";
import UserAlerts from "../Events/UserAlerts";
import { unaccompaniedVisitAllowed } from "../Events/utils";
import buildPageTitle from "../lib/buildPageTitle";
import { ListSkeleton } from "../lib/closet";
import featureEnabled from "../lib/featureEnabled";
import Link, { Props as LinkProps } from "../lib/Link";
import Screen from "../lib/Screen";
import useConnectionsFeature from "../lib/useConnectionsFeature";
import useFeaturePermitted from "../lib/useFeaturePermitted";
import useVoiceCallPermission from "../lib/useVoiceCallPermission";
import { useCurrentCorrespondent } from "../SessionBoundary";

type LocationState = {
  newUser?: boolean;
};

function ModuleHeader({
  title,
  action,
}: {
  title: string;
  action?: Pick<LinkProps, "to" | "state" | "children" | "button"> & {
    disabled?: boolean;
    disabledReason?: string;
    variant?: "contained" | "outlined";
  };
}) {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems={{ sm: "center" }}
      justifyContent={{ sm: "space-between" }}
      spacing={1}
    >
      <Typography variant="h2">{title}</Typography>
      {action && (
        <Link
          button
          size="small"
          variant={action.variant || "contained"}
          disabled={action.disabled}
          disabledReason={action.disabledReason}
          {...action}
        />
      )}
    </Stack>
  );
}

export default function HomepageScreen() {
  const { t } = useTranslation();
  const headerTitle = t("Home");
  const pageTitle = buildPageTitle(headerTitle);
  const user = useCurrentCorrespondent();
  const { data: eventsData, refetch } = useEventsQuery();

  const location = useLocation();
  const isNewUser = useMemo(
    () => (location.state as LocationState)?.newUser ?? undefined,
    [location.state]
  );

  const videoCallingEnabled = useConnectionsFeature(FacilityFeature.VideoCall);
  const visitingEnabled = useConnectionsFeature(FacilityFeature.InPersonVisit);

  const inmateFacilityHasEvents =
    user.__typename === "Inmate" &&
    [
      FacilityFeature.VideoCall,
      FacilityFeature.InPersonVisit,
      FacilityFeature.ConfidentialInPersonVisit,
      FacilityFeature.ConfidentialVideoCall,
    ].some((f) => user.facility.features.includes(f));

  const upcomingMeetings = useMemo(
    () =>
      sortByDates(
        eventsData?.currentCorrespondent?.meetings.edges
          .map((e) => e.node)
          .filter((meeting) =>
            [
              MeetingStatus.PendingApproval,
              MeetingStatus.Scheduled,
              MeetingStatus.Live,
            ].includes(meeting.status)
          ) || [],
        (m) => new Date(m.interval.startAt)
      ).slice(0, 3),
    [eventsData]
  );

  const { data: connectionsData, error } = useQuery(GetConnectionsDocument, {
    fetchPolicy: "cache-and-network",
  });

  const { data: facilityContactsData, error: facilityContactsError } = useQuery(
    GetContactsScreenDataDocument,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const { nextVoiceCallSlot, userMayMakeVoiceCall } = useVoiceCallPermission(
    user,
    facilityContactsData?.currentInmate?.group
  );

  const mayInitiateVoiceCall = useFeaturePermitted(
    CorrespondentFeature.MakeVoiceCalls
  );

  const mayScheduleEvents = useFeaturePermitted(
    CorrespondentFeature.ScheduleMeetings
  );

  const [meetingToCancel, setMeetingToCancel] = useState<
    | {
        description: string;
        meetingId: string;
      }
    | undefined
  >();

  const [meetingToLeave, setMeetingToLeave] = useState<
    | {
        description: string;
        meetingId: string;
      }
    | undefined
  >();

  const hasApprovedContacts = useMemo(
    () =>
      connectionsData?.currentCorrespondent?.connections.some(
        (c) => c.status === ConnectionStatus.Active
      ),
    [connectionsData]
  );

  const apiError = error || facilityContactsError;
  if (apiError) throw apiError;

  if (
    !eventsData?.currentCorrespondent ||
    !connectionsData?.currentCorrespondent
  )
    return <ListSkeleton />;

  // Get all connections except inactive
  const connections = sortByDates(
    connectionsData.currentCorrespondent.connections.filter(
      (c) => c.status !== ConnectionStatus.Inactive
    ),
    (r) => new Date(r.lastInteractionAt)
  ).reverse();

  const mainConnections = connections.slice(0, 3);

  const renderContent = () => {
    if (connections.length === 0) {
      if (featureEnabled("HOMEPAGE") && isNewUser) {
        return (
          <EmptyScreen
            message={t(
              "Your account has been created. Next, get started by adding a new contact."
            )}
            illustration={SignupSuccess}
            illustrationWidth="318"
            linkProps={
              user.__typename === "Visitor"
                ? {
                    label: t("Request contact"),
                    to: "/contacts/add",
                    state: {
                      fromPath: "/",
                      fromName: t("Home"),
                    },
                  }
                : undefined
            }
          />
        );
      }
      return (
        <NoContactsScreen
          linkProps={
            user.__typename === "Visitor"
              ? {
                  label: t("Request contact"),
                  to: "/contacts/add",
                  state: {
                    fromPath: "/",
                    fromName: t("Home"),
                  },
                }
              : undefined
          }
        />
      );
    }

    const showEventsModule =
      visitingEnabled || videoCallingEnabled || inmateFacilityHasEvents;

    return (
      <Stack spacing={7}>
        {showEventsModule && (
          <Box width="100%">
            <ModuleHeader
              title={t("Upcoming events")}
              action={
                mayScheduleEvents
                  ? {
                      to: "/events/new",
                      state: { fromPath: "/", fromName: t("Home") },
                      children: t("Schedule event"),
                      button: true,
                      disabled: !hasApprovedContacts,
                      disabledReason: hasApprovedContacts
                        ? t("You must have at least one approved contact")
                        : undefined,
                    }
                  : undefined
              }
            />
            {upcomingMeetings.length > 0 ? (
              <Stack spacing={2} my={2}>
                {upcomingMeetings.map((m) => (
                  <EventCard
                    showDateInfo
                    key={m.id}
                    meeting={m}
                    onCancel={setMeetingToCancel}
                    onLeaveEvent={setMeetingToLeave}
                  />
                ))}
              </Stack>
            ) : (
              <Box py={3}>
                <Typography variant="body1">
                  {t("You do not have any events scheduled.")}
                </Typography>
              </Box>
            )}
            <Box textAlign="right">
              <Link to="/events">{t("See all events")}</Link>
            </Box>
          </Box>
        )}
        <Box width="100%">
          <ModuleHeader
            title={t("Top contacts")}
            action={
              user.__typename === "Visitor"
                ? {
                    to: "/contacts/add",
                    state: {
                      fromPath: "/",
                      fromName: t("Home"),
                    },
                    children: t("Request contact"),
                    variant: showEventsModule ? "outlined" : "contained",
                  }
                : undefined
            }
          />
          <Stack spacing={2} my={2}>
            {mainConnections.map((connection) => (
              <ConnectionCard
                key={connection.id}
                mayMakeVoiceCall={userMayMakeVoiceCall}
                nextVoiceCallSlot={nextVoiceCallSlot}
                showVoiceCall={
                  mayInitiateVoiceCall &&
                  connection.status === ConnectionStatus.Active
                }
                showMessage={
                  user.__typename === "Visitor" &&
                  connection.features.includes(
                    ConnectionFeature.SendMessages
                  ) &&
                  !connection.activeRestrictions.find(
                    (r) => r.service === FacilityService.Emessaging
                  ) &&
                  connection.status === ConnectionStatus.Active
                }
                showScheduleEvent={
                  mayScheduleEvents &&
                  connection.status === ConnectionStatus.Active &&
                  unaccompaniedVisitAllowed(user, connection.id)
                }
                connection={connection}
              />
            ))}
          </Stack>
          <Box textAlign="right">
            <Link to="/contacts">{t("See all contacts")}</Link>
          </Box>
        </Box>
      </Stack>
    );
  };

  return (
    <Screen showNotifications title={headerTitle}>
      {meetingToCancel && (
        <CancelMeetingDialog
          meetingToCancel={meetingToCancel}
          onClose={() => setMeetingToCancel(undefined)}
        />
      )}
      {meetingToLeave && (
        <LeaveMeetingDialog
          meetingToLeave={meetingToLeave}
          onClose={async () => {
            refetch();
            setMeetingToLeave(undefined);
          }}
        />
      )}
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <UserAlerts />
      {renderContent()}
    </Screen>
  );
}
