import { useQuery } from "@apollo/client";
import { FacilityFeature } from "../api/graphql";
import { GetConnectionFeaturesDocument } from "./GetConnectionFeatures.generated";

export default function useConnectionsFeature(
  feature: FacilityFeature
): boolean {
  const { data } = useQuery(GetConnectionFeaturesDocument);
  const facilities = (data?.currentVisitor?.connections || []).map(
    (c) => c.inmate.facility
  );
  return facilities.some((f) => f.features.includes(feature));
}
