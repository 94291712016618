import { ConnectionFeature, ConnectionStatus } from "../api/graphql";
import { GetCurrentUserQuery } from "../GetCurrentUser.generated";

/**
 * Simple helper function to combine logic specific to a visitor/inmate
 * and messaging feature enablement
 */
export default function messagingEnabled(
  user: GetCurrentUserQuery["me"]
): boolean {
  return (
    (user?.__typename === "Visitor" || user?.__typename === "Inmate") &&
    user.connections
      .filter((c) => c.status === ConnectionStatus.Active)
      .some((c) => c.features.includes(ConnectionFeature.SendMessages))
  );
}
