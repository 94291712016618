import { useQuery } from "@apollo/client";
import { subMonths } from "date-fns";
import { useEffect } from "react";
import featureEnabled from "../lib/featureEnabled";
import { useServerEvent } from "../lib/serverEventsProvider";
import { GetEventsDocument } from "./GetEvents.generated";
import { GetEventsWithVoiceCallsDocument } from "./GetEventsWithVoiceCalls.generated";
import useMountedPolling from "./useMountedPolling";

const useEventsQuery = () => {
  const dayStart = new Date();
  dayStart.setHours(0, 0, 0, 0);

  const {
    data,
    error,
    startPolling,
    stopPolling,
    refetch,
    fetchMore,
    loading,
  } = useQuery(
    featureEnabled("HOMEPAGE")
      ? GetEventsWithVoiceCallsDocument
      : GetEventsDocument,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        dayStart: subMonths(dayStart, 1).getTime(),
        before: undefined,
        after: undefined,
      },
    }
  );
  useMountedPolling(30000, startPolling, stopPolling);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useServerEvent("videoCallInitialized", () => {
    refetch();
  });

  return { data, error, refetch, fetchMore, loading };
};

export default useEventsQuery;
