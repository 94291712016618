import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect } from "socket.io-client";
import { getToken } from "./authToken";

const API_HOST = import.meta.env.VITE_API_ORIGIN?.split("://").pop();

export type ServerToClientEvents = {
  updateConnectionTimeline: { id: string };
  updateMessageStatus: { id: string };
  approveConnection: { id: string };
  voiceCallDeclined: { id: string };
  voiceCallEnded: { id: string; userInitiated: boolean };
  newVoiceCall: { id: string };
  videoCallInitialized: { id: string };
  newInstantMessageReply: {
    id: string;
    sender: string;
    content: string;
  };
};

// TODO: Socket<ServerToClientEvents, ClientToServerEvents> in v4
type TypedSocket = {
  /** register an event listener */
  on: <N extends keyof ServerToClientEvents>(
    event: keyof ServerToClientEvents extends string ? string : null,
    listener: (data: ServerToClientEvents[N]) => void
  ) => void;
  /** remove an event listener */
  off: <N extends keyof ServerToClientEvents>(
    event: keyof ServerToClientEvents extends string ? string : null,
    listener: (data: ServerToClientEvents[N]) => void
  ) => void;
};

const MockClient: TypedSocket = {
  on: () => null,
  off: () => null,
};

type ServerEventsContextType = {
  client: TypedSocket;
};

const ServerEventsContext = React.createContext<ServerEventsContextType>({
  client: MockClient,
});

function ServerEventsProvider({ children }: { children: React.ReactNode }) {
  // initialize a connected client
  const [client, setClient] = useState<TypedSocket>(MockClient);
  const token = getToken();
  useEffect(() => {
    if (!API_HOST) return undefined;
    if (!token) return undefined;

    const socket = connect(API_HOST, {
      transports: ["websocket"],
      auth: {
        token: token.replace("Bearer ", ""),
      },
    });
    socket.on("connect", () => {
      // console.log({ connected: socket.connected });
    });
    // This handler can take an argument, containing the error,
    // if it wants to.
    socket.on("connect_error", () => {
      // console.error(e);
    });

    setClient(socket);

    return () => {
      socket?.close();
      setClient(MockClient);
      return undefined;
    };
  }, [token]);

  // avoid context churn
  const value = useMemo(() => ({ client }), [client]);

  return (
    <ServerEventsContext.Provider value={value}>
      {children}
    </ServerEventsContext.Provider>
  );
}
export function useServerEvent<N extends keyof ServerToClientEvents>(
  name: N,
  fn: (data: ServerToClientEvents[N]) => void
) {
  const { client } = useContext(ServerEventsContext);
  useEffect(() => {
    client.on(name, fn);
    return () => {
      client.off(name, fn);
    };
  }, [client, fn, name]);
}

export default ServerEventsProvider;
