import { Chip, ChipCustomColors } from "@ameelio/ui";
import React from "react";
import { MeetingStatus, MeetingType } from "../api/graphql";
import getMeetingStatusLabelByType from "./getMeetingStatusLabelByType";

export default function MeetingStatusChip({
  status,
  meetingType,
}: {
  status: MeetingStatus;
  meetingType: MeetingType;
}) {
  const label = getMeetingStatusLabelByType(status, meetingType);
  let chipColor: ChipCustomColors | null = null;
  switch (status) {
    case MeetingStatus.PendingApproval:
      chipColor = "orange";
      break;
    case MeetingStatus.Cancelled:
    case MeetingStatus.Rejected:
      chipColor = "red";
      break;
    case MeetingStatus.Terminated:
    case MeetingStatus.NoShow:
      chipColor = "grey";
      break;
    case MeetingStatus.Ended:
    case MeetingStatus.Scheduled:
    case MeetingStatus.Live:
      chipColor = "green";
      break;
    default:
      throw new Error("unexpected meeting status");
  }
  return chipColor ? <Chip color={chipColor} label={label} /> : null;
}
