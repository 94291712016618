import { MeetingType } from "../api/graphql";
import i18n from "../i18n/i18nConfig";

export default function facilityServiceLabel(mt: MeetingType | "EMESSAGING") {
  const Labels: Record<MeetingType | "EMESSAGING", string> = {
    [MeetingType.InPersonVisit]: i18n.t("visits"),
    [MeetingType.VideoCall]: i18n.t("video calls"),
    [MeetingType.VoiceCall]: i18n.t("voice calls"),
    [MeetingType.Webinar]: i18n.t("webinars"),
    EMESSAGING: i18n.t("e-messaging"),
  };
  return Labels[mt];
}
