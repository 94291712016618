import { SnackbarProvider } from "@ameelio/ui";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as Sentry from "@sentry/react";
import React, { Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "./analytics";
import "./App.css";
import appRoutes from "./appRoutes";
import AppSkeleton from "./AppSkeleton";
import AuthenticatedClientProvider from "./AuthenticatedClientProvider";
import AuthProvider from "./AuthProvider";
import SegmenterProvider from "./Call/LiveCall/SegmenterProvider";
import CurrentUserProvider from "./CurrentUserProvider";
import { ErrorFallback } from "./ErrorScreen";
import buildPageTitle from "./lib/buildPageTitle";
import GlobalServerEventsListener from "./lib/GlobalServerEventsListener";
import { browserToI18n } from "./lib/Language";
import getLanguageDirection from "./lib/languageDir";
import ResetScroll from "./lib/ResetScroll";
import ServerEventsProvider from "./lib/serverEventsProvider";
import StyleProvider from "./lib/StyleProvider";
import MessageBadgeProvider from "./Messages/MessageBadgeProvider";
import { useInstrumentedRoutes } from "./sentry";
import { useCurrentLanguageDirection } from "./SessionBoundary";
import UnauthenticatedClientProvider from "./UnauthenticatedClientProvider";

export default function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const lng = browserToI18n(navigator.language);
    i18n.changeLanguage(lng);
    document.documentElement.lang = lng;
  }, [i18n]);

  useEffect(() => {
    document.documentElement.dir = getLanguageDirection();
  }, [i18n, i18n.language]);

  // this is also currently responsible for initializing sentry
  const Screen = useInstrumentedRoutes(appRoutes);

  const location = useLocation();
  const languageDir = useCurrentLanguageDirection();
  const title = buildPageTitle();

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SegmenterProvider>
          <SnackbarProvider>
            <UnauthenticatedClientProvider>
              <AuthProvider>
                <AuthenticatedClientProvider>
                  <StyleProvider>
                    <ResetScroll key={location.pathname} />
                    <CurrentUserProvider>
                      <ServerEventsProvider>
                        <MessageBadgeProvider>
                          <GlobalServerEventsListener />
                          <Suspense
                            fallback={<AppSkeleton />}
                            key={languageDir}
                          >
                            <Helmet>
                              <title>{title}</title>
                            </Helmet>
                            {Screen}
                          </Suspense>
                        </MessageBadgeProvider>
                      </ServerEventsProvider>
                    </CurrentUserProvider>
                  </StyleProvider>
                </AuthenticatedClientProvider>
              </AuthProvider>
            </UnauthenticatedClientProvider>
          </SnackbarProvider>
        </SegmenterProvider>
      </LocalizationProvider>
    </Sentry.ErrorBoundary>
  );
}
