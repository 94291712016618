import { useMemo } from "react";
import useMayScheduleWebinars from "../../lib/useMayScheduleWebinars";
import { useCurrentVisitor } from "../../SessionBoundary";

export type WorkflowAction = "create" | "edit" | "duplicate";

type Props = {
  isWebinar: boolean;
  stepsToRemove?: Step[];
};

export type Step =
  | "selectEventType"
  | "selectWho"
  | "addGuests"
  | "selectWhen"
  | "confirm"
  | "finished";

const defaultSteps: Step[] = ["selectWhen", "confirm", "finished"];

export default function useWorkflowSteps({
  isWebinar,
  stepsToRemove,
}: Props): Step[] {
  const user = useCurrentVisitor();
  const mayScheduleWebinars = useMayScheduleWebinars();
  const orgMembership = user.organizationMemberships[0];

  // useMemo so the result can be a dependency for a useCallback
  return useMemo(() => {
    const steps: Step[] =
      mayScheduleWebinars && isWebinar
        ? ["selectEventType", "selectWho", ...defaultSteps]
        : // Non-org member or org member having colleagues
          !orgMembership || orgMembership?.organization.memberships.length > 1
          ? ["selectWho", "selectEventType", "addGuests", ...defaultSteps]
          : // If the user is an org member in an org with a single
            // member (themselves), we can just skip the addGuests step
            ["selectWho", "selectEventType", ...defaultSteps];

    // If steps to be removed are provided, filter them out of the returned list
    if (stepsToRemove?.length)
      return steps.filter((step) => !stepsToRemove.includes(step));
    return steps;
  }, [mayScheduleWebinars, isWebinar, orgMembership, stepsToRemove]);
}
