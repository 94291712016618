enum NotificationEventType {
  LEGACY_NOTIFICATION = "legacy_notification",
  // Connection
  CONTACT_ARCHIVED = "contact_archived",
  CONTACT_REMOVED = "contact_removed",
  CONTACT_REQUEST_APPROVED = "contact_request_approved",
  CONTACT_REQUEST_CREATED = "contact_request_created",
  CONTACT_REQUEST_REJECTED = "contact_request_rejected",

  // Connection Restriction
  CONNECTION_RESTRICTION_CREATED = "connection_restriction_created",
  CONNECTION_RESTRICTION_REMOVED = "connection_restriction_removed",

  // ID Approval
  ID_APPROVAL_REQUEST = "id_approval_request",
  ID_APPROVAL_REMINDER = "id_approval_reminder",
  ID_APPROVAL_REJECTED = "id_approval_rejected",
  // Message
  MESSAGE_CREATED = "message_created",
  MESSAGE_REJECTED = "message_rejected",
  // Meeting
  MEETING_APPROVED = "meeting_approved",
  MEETING_CANCELED = "meeting_canceled",
  MEETING_CANCELED_WITHOUT_KIOSKS = "meeting_canceled_without_kiosks",
  MEETING_CREATED = "meeting_created",
  MEETING_MISSED = "meeting_missed",
  MEETING_REJECTED = "meeting_rejected",
  MEETING_REMINDER = "meeting_reminder",
  MEETING_TERMINATED = "meeting_terminated",
  MEETING_UPDATED = "meeting_updated",

  // Meeting Participant
  MEETING_PARTICIPANT_REMOVED = "meeting_participant_removed",
  MEETING_PARTICIPANT_UPDATED = "meeting_participant_updated",

  // Organization
  ORGANIZATION_MEMBERSHIP_CREATED = "organization_membership_created",

  // Screenshots
  SCREENSHOTS_VIEWED = "screenshots_viewed",
  // Voice calls
  VOICE_CALL_CALLING = "voice_call_calling",
}

export default NotificationEventType;
