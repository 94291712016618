import { Location } from "react-router-dom";
import isObjectWithKey from "./isObjectWithKey";

export type WorkflowState = {
  fromPath: string;
  fromName: string;
};

export function isWorkflowState(obj: unknown): obj is WorkflowState {
  return (
    isObjectWithKey(obj, "fromPath") &&
    typeof obj.fromPath === "string" &&
    isObjectWithKey(obj, "fromName") &&
    typeof obj.fromName === "string"
  );
}

export default function getWorkflowState(
  location: Location
): WorkflowState | undefined {
  if (isWorkflowState(location.state)) return location.state;
  return undefined;
}
